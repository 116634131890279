import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Config } from '@constants'
import HelmetHelper from '@components/HelmetHelper'
import Layout from '@components/Layout'
import ArticlePreview from '@components/ArticlePreview'
import BottomCta from '@components/BottomCta'
import Typography from '@atoms/Typography'
import SubscribeForm from '@components/custom/SubscribeForm'
import SubscribeBox from '@components/custom/SubscribeBox'

const BlogIndex = (props) => {
  const posts = get(props, 'data.allContentfulBlogPost.nodes')

  return (
    <Layout>
      <div className="relative bg-white">
        <HelmetHelper
          title={`LinkedIn Outreach Tips - ${Config.NAME}`}
          description={`Get the ${Config.NAME}'s industry insights and growth hacks delivered to your inbox every month and learn how to drive more business from LinkedIn.`}
        />
        <div className="pt-16 pb-10 text-center bg-white space-y-5">
          <Typography.H1>LinkedIn Outreach Tips</Typography.H1>
          <Typography.SUBHEADER component="h2">
            Get access to new industry insights and growth hacks delivered to
            your inbox every month and learn how to drive more business from
            LinkedIn.
          </Typography.SUBHEADER>
        </div>
        <SubscribeBox component={SubscribeForm} />
        <div className="max-w-7xl mx-auto pb-20 mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-16">
            {posts.map((post) => (
              <div key={post.slug}>
                <ArticlePreview data={post} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <BottomCta />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        author {
          name
          title
          company
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 60
              height: 60
            )
          }
        }
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(
            placeholder: BLURRED
            height: 220
            width: 400
            layout: FULL_WIDTH
          )
        }
        description {
          description
        }
      }
    }
  }
`
