import { Toaster } from 'react-hot-toast'
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@atoms/Button'
import { Routes } from '@constants'
import Redirect from '@atoms/Redirect'

const SubscribeForm = ({ value, handleOnChange, handleOnSubmit }) => (
  <>
    <div className="max-w-xl mx-auto text-center w-full pb-20">
      <form
        onSubmit={handleOnSubmit}
        className="md:flex justify-start md:gap-4 w-full space-y-3 md:space-y-0"
      >
        <input
          type="email"
          required
          placeholder="Enter your email"
          className="w-full text-lg py-4 px-3 border border-solid border-gray-300 rounded-md focus:border-primary-light placeholder-gray-500"
          value={value}
          onChange={handleOnChange}
        />
        <Button size="big" className="w-full md:w-auto" type="submit">
          Subscribe
        </Button>
      </form>
      <div className="text-xm md:text-sm mt-4">
        You may opt out any time.{' '}
        <Redirect
          className="text-link hover:underline"
          href={Routes.LEGAL.TOS}
          external
        >
          Terms
        </Redirect>{' '}
        and{' '}
        <Redirect
          className="text-link hover:underline"
          href={Routes.LEGAL.PRIVACY_POLICY}
          external
        >
          Privacy Policy
        </Redirect>
        .
      </div>
    </div>
    <Toaster />
  </>
)

SubscribeForm.propTypes = {
  value: PropTypes.node.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
}

export default SubscribeForm
