import toast, { Toaster } from 'react-hot-toast'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Config } from '@constants'
import imgAuthor from '@static/favicon/apple-touch-icon.png'

const SubscribeBox = ({ component: Component }) => {
  const [value, setValue] = useState('')

  async function addToSengridEmailList() {
    try {
      const options = {
        method: 'PUT',
        body: JSON.stringify({
          list_ids: [Config.SENGRID.LIST_ID],
          contacts: [
            {
              email: value,
            },
          ],
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${Config.SENGRID.API_KEY}`,
        },
        json: true,
      }

      fetch(Config.SENGRID.API_URL, options)
        .then((response) => response.json())
        .then(() => {
          // reset form input
          setValue('')

          // show toast
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? 'animate-enter' : 'animate-leave'
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={imgAuthor}
                      alt="blog-author"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      You&apos;re in!
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Thank you for subscribing the {Config.NAME} newsletter.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  type="button"
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </div>
            </div>
          ))
        })
    } catch (err) {
      throw new Error(err)
    }
  }

  const handleOnSubmit = (event) => {
    event.preventDefault()

    if (!value) {
      return toast.error('You need to insert your email address.')
    }
    return addToSengridEmailList()
  }

  const handleOnChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <>
      <Component
        value={value}
        handleOnChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
      />
      <Toaster />
    </>
  )
}

SubscribeBox.propTypes = {
  component: PropTypes.func.isRequired,
}

export default SubscribeBox
